<template>
    <footer>
        <div v-if="currentPage !== 'free-trial'">
            <div style="background-color: #3987d8; width:100%; height: 10rem;"></div>
            <img class="paper-plane" :src="`${routes.cdn_url}/images/redesign/home/paper-plane.svg`" loading="lazy" alt="A paper plane soaring through the air"/>
            <b-row class="mx-auto footer-cta-container">
                <b-col cols="12" md="8" xl="6" class="footer-cta p-4">
                    <b-row align-v="center" class="justify-content-between">
                        <b-col col md="6" lg="5">
                            <h4 style="opacity:0.65;">Ready to get started?</h4>
                            <h4>Talk to us today</h4>
                        </b-col>
                        <b-col col md="6" lg="4">
                            <li :class="{active: currentPage == 'free-trial'}" class="nav-item bg-success" style="border-radius: 6px !important;">
                                <Link href="/free-trial" class="nav-link">Schedule my free pilot!</Link>
                            </li>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <b-row class="footerTop">
            <b-col cols="6" md="3">
                <b-nav vertical>
                    <b-img :src="`${routes.cdn_url}/images/logo/LOGO-NGM-reverse-on-blue.svg`" class="w-75" loading="lazy" alt="Next Gen Math"></b-img>
                    <br/>
                    <h3><i>Dynamic resources for unlimited opportunities</i></h3>
                    <ul class="p-0">
                        <li class="nav-item" :class="{active: currentPage == 'careers'}">
                            <Link href="/careers" class="nav-link pl-0">Careers</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'districts'}">
                            <Link href="/districts" class="nav-link pl-0">Districts</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'administrators'}">
                            <Link href="/administrators" class="nav-link pl-0">Administrators</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'teachers'}">
                            <Link href="/teachers" class="nav-link pl-0">Teachers</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'students'}">
                            <Link href="/students" class="nav-link pl-0">Students</Link>
                        </li>
                    </ul>
                </b-nav>
            </b-col>
            <b-col cols="6" md="3">
                <b-nav vertical>
                    <h4>WHY NEXT GEN MATH?</h4>
                    <ul class="p-0">
                        <li class="nav-item" :class="{active: currentPage == 'mtss'}">
                            <Link href="/mtss" class="nav-link pl-0">Multi-Tiered System of Supports</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'english-language-development'}">
                            <Link href="/english-language-development" class="nav-link pl-0">English Language Development</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'english-learner-support'}">
                            <Link href="/english-learner-support" class="nav-link pl-0">English Learner Support</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'gifted-and-talented'}">
                            <Link href="/gifted-and-talented" class="nav-link pl-0">Gifted and Talented</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'special-education'}">
                            <Link href="/special-education" class="nav-link pl-0">Special Education</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'udl'}">
                            <Link href="/udl" class="nav-link pl-0">Universal Design for Learning</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'mastery-learning'}">
                            <Link href="/mastery-learning" class="nav-link pl-0">Mastery Learning</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'interdisciplinary-content'}">
                            <Link href="/interdisciplinary-content" class="nav-link pl-0">Interdisciplinary Content</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'dynamic-assessments'}">
                            <Link href="/dynamic-assessments" class="nav-link pl-0">Dynamic Assessments</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'standards-aligned'}">
                            <Link href="/standards-aligned" class="nav-link pl-0">Standards Aligned</Link>
                        </li>
                    </ul>
                </b-nav>
            </b-col>
            <b-col cols="6" md="3">
                <b-nav vertical>
                    <h4>TEACHING & LEARNING</h4>
                    <ul class="p-0">
                        <li class="nav-item" :class="{active: currentPage == 'blended-learning'}">
                            <Link href="/blended-learning" class="nav-link pl-0">Blended Learning</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'distance-learning'}">
                            <Link href="/distance-learning" class="nav-link pl-0">Distance Learning</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'procedural-fluency'}">
                            <Link href="/procedural-fluency" class="nav-link pl-0">Procedural Fluency</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'standards-practice'}">
                            <Link href="/standards-practice" class="nav-link pl-0">Standards Practice</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'analytics'}">
                            <Link href="/analytics" class="nav-link pl-0">Realtime Analytics</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'instructional-design'}">
                            <Link href="/instructional-design" class="nav-link pl-0">Instructional Design</Link>
                        </li>
                    </ul>
                </b-nav>
            </b-col>
            <b-col cols="6" md="3">
                <b-nav vertical>
                    <h4>RESOURCES</h4>
                    <ul class="p-0">
                        <!-- <li class="nav-item" :class="{active: currentPage == 'webinar-schedule'}">
                            <Link href="/webinar-schedule" class="nav-link pl-0">Webinar Schedule</Link>
                        </li> -->
                        <li class="nav-item" :class="{active: currentPage == 'implementation-models'}">
                            <Link href="/implementation-models" class="nav-link pl-0">Implementation Models</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'partner-success'}">
                            <Link href="/partner-success" class="nav-link pl-0">Partner Success</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'testimonials'}">
                            <Link href="/testimonials" class="nav-link pl-0">Testimonials</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'faq'}">
                            <Link href="/faq" class="nav-link pl-0">FAQ</Link>
                        </li>
                        <li class="nav-item">
                            <a href="https://support.nextgenmath.com/hc/en-us/categories/10033631011227" class="nav-link pl-0" target="_blank" rel="noopener noreferrer">Blog</a>
                        </li>
                        <li class="nav-item">
                            <a href="https://support.nextgenmath.com/hc/en-us" class="nav-link pl-0" target="_blank" rel="noopener noreferrer">Support</a>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'privacy-policy'}">
                            <Link href="/privacy-policy" class="nav-link pl-0">Privacy Agreement</Link>
                        </li>
                        <li class="nav-item" :class="{active: currentPage == 'contact-us'}">
                            <a href="/contact-us" class="nav-link pl-0">Contact Us</a>
                        </li>
                    </ul>
                    <h4 class="mt-5">SOCIAL</h4>
                    <b-row class="ml-0">
                        <b-nav-item 
                            href="https://www.facebook.com/nextgenmath/"
                            target="_blank"
                            rel="noopener noreferrer nofollow">
                            <font-awesome-icon class="text-white" :icon="['fab', 'facebook']"/>
                        </b-nav-item>
                        <b-nav-item 
                            href="https://www.linkedin.com/company/nextgenmath/"
                            target="_blank"
                            rel="noopener noreferrer nofollow">
                            <font-awesome-icon class="text-white" :icon="['fab', 'linkedin']"/>
                        </b-nav-item>
                        <b-nav-item 
                            href="https://twitter.com/next_gen_math"
                            target="_blank"
                            rel="noopener noreferrer nofollow">
                            <font-awesome-icon class="text-white" :icon="['fab', 'x-twitter']"/>
                        </b-nav-item>
                        <b-nav-item 
                            href="https://www.youtube.com/channel/UCWSc54jBYxO7btQAZWKSqOw/featured"
                            target="_blank"
                            rel="noopener noreferrer nofollow">
                            <font-awesome-icon class="text-white" :icon="['fab', 'youtube']"/>
                        </b-nav-item>
                    </b-row>
                </b-nav>
            </b-col>
        </b-row>
        <b-row class="footerBottom">
            <p class="">Next Gen Math, LLC &copy; {{new Date().getFullYear()}}</p>
        </b-row>
    </footer>
</template>

<script>
    export default {  
        data: () => ({
            activeClass: 'active',
        }),
        computed: {
            currentPage() {
                return this.$page.url.replaceAll('/', '');
            }
        },
    }
</script>

<style lang="scss" scoped>
    footer {
        color: #fff;
        background: #005ec0;
        overflow: hidden;
        font-size: 16px;
        line-height: 120%;
    }
    .footer-cta-container {
        background: linear-gradient(#3987d8 50%, #005ec0 50%);
    }
    .footer-cta {
        background: #030744;
        border-radius: 6px;
        margin: 0 auto;
        .nav-item {
            color: #000;
            font-weight: 600;
            text-decoration: none;
            list-style: none;
            .nav-link {
                color: #000;
                text-align: center;
            }
        }
    }
    .paper-plane {
        position: absolute;
        width: 100%;
        margin-top: -8rem;
        pointer-events: none;
        z-index: 5;
    }
    .footerTop {
        margin-top: 8rem;
        padding: 3rem;
        h3 {
            font-size: 1.3rem;    
        }
        h3, h4 {
            color: rgba(255,255,255,0.6);
        }
        li {
            list-style: none;
        }
        .nav-link {
            text-decoration: underline;
            &:hover {
                color: #BBDF00;
            }
        }
        p {
            margin-bottom: 0;
        }
        a {
            color: #fff;
        }
    }
    .footerBottom {
        height: 6vh;
        padding: 1rem;
        justify-content: center;
        background: #030744;
        font-size: 16px;
    }

    .active {
        a {
            color: #bbdf00;
        }

        font-weight: bold;
    }

    @media (max-width: 1600px) {
        .paper-plane {
            margin-top: -6rem;
        }
    }
    @media (max-width: 1400px) {
        .footerTop {
            margin-top: 4rem;
        }
        .paper-plane {
            margin-top: -6rem;
        }
    }
    @media (max-width: 1200px) {
        .paper-plane {
            margin-top: -4rem;
        }
    }
    @media (max-width: 992px) {
        .footerTop {
            margin-top: 3rem;
        }
    }
    @media (max-width: 768px) {
        .paper-plane {
            margin-top: -3rem;
        }
    }
    @media (max-width: 576px) {
        footer {
            margin-top: -4vh;
        }
        .paper-plane {
            margin-top: -2rem;
        }
    }
</style>
